.form-control {
  margin: 30px 0px;
}
.input {
  padding: 5px;
}
.input:focus,
textarea:focus {
  border-bottom: 2px solid #4caf50;
}
.lows:focus {
  border-bottom: none;
}
.input:focus + .label,
textarea:focus + .label {
  transform: translateY(-1em) scale(0.8);
  -webkit-transform: translateY(-1em) scale(0.8);
  -moz-transform: translateY(-1em) scale(0.8);
  -ms-transform: translateY(-1em) scale(0.8);
  -o-transform: translateY(-1em) scale(0.8);
}
.input:not(:placeholder-shown) + .label,
textarea:not(:placeholder-shown) + .label {
  transform: translateY(-1em) scale(0.8);
  -webkit-transform: translateY(-1em);
  -moz-transform: translateY(-1em) scale(0.8);
  -ms-transform: translateY(-1em) scale(0.8);
  -o-transform: translateY(-1em) scale(0.8);
}
