/* TailwindCss */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    body {
        @apply bg-white
    }
}

@layer components {
    .inputForm{
        @apply text-white max-w-lg w-full focus:outline-none border-b-2 pb-4 border-inputColor bg-primary;
    }

    .inputSecondary{
        @apply w-full p-2 mb-8 text-center rounded border text-white uppercase focus:outline-none hover:bg-black/20;
    }

    .inputFormPrefix{
        @apply text-white max-w-lg w-full focus:outline-none border-b-2 pb-4 border-inputColor bg-primary pl-10 pr-4;
    }

    .buttonForm{
        @apply bg-secondary w-full font-bold text-sm py-2.5 text-white rounded text-center hover:bg-green-600;
    }

    .textButtonForm{
        @apply w-full max-w-lg font-bold  text-sm py-2 text-white rounded text-center hover:bg-black hover:bg-opacity-10;
    }

    .outlinedButton{
        @apply w-full max-w-lg bg-transparent border-[1.5px] rounded-sm border-white border-opacity-60 py-2 text-sm text-white text-opacity-60 hover:bg-black hover:bg-opacity-10;;
    }

    .InputForm2{
        @apply text-inputColor  w-full md:w-[30rem] focus:outline-none border-b-2 py-4  border-inputColor bg-primary;
    }

    .SelectForm{
        @apply    text-inputColor w-full  md:w-[30rem]  border-b-2 py-4 border-inputColor bg-primary;
    }

    .landingRightNavItem{
        @apply cursor-pointer flex flex-col items-center
    }

    .lagislationCard{
        @apply col-span-1 flex-col  mb-6 bg-white shadow-xl ;
    }

    .btnGreen {
        @apply bg-secondary px-[84px] py-5 font-bold  text-sm text-white text-center cursor-pointer;
    }

    .btnWhite {
        @apply bg-white px-[84px] py-5 font-bold  text-sm text-mygrey text-center cursor-pointer;
    }
    .textSecure {
        @apply pb-3 text-sm md:text-base text-black;
    }
}